import Script from 'next/script';
import React from 'react';

interface Props {}

const TrustpilotFooter: React.FC<Props> = () => (
  <>
    <div
      className="trustpilot-widget my-8 text-center"
      data-locale="en-US"
      data-template-id="5419b637fa0340045cd0c936"
      data-businessunit-id="55d25dce0000ff000582430a"
      data-style-height="20px"
      data-style-width="100%"
      data-theme="dark"
    >
      <a
        href="https://www.trustpilot.com/review/sidelineswap.com"
        target="_blank"
        rel="noopener noreferrer"
      >
        Trustpilot
      </a>
    </div>

    <Script
      src="//widget.trustpilot.com/bootstrap/v5/tp.widget.bootstrap.min.js"
      strategy="lazyOnload"
    />
  </>
);

export default TrustpilotFooter;
