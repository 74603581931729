import { Form, Formik } from 'formik';
import { FormikFieldError, FormikInput } from 'formik-ui';
import { useRouter } from 'next/router';
import React from 'react';
import { Button } from 'ui';
import * as yup from 'yup';
import { postEmailCapture } from '~/api/user';
import { setKnownCookie } from '~/hooks/cookies';
import { AnalyticsSource } from '~/services/analytics/AnalyticsInterfaces';
import { fireEmailCaptured } from '~/services/analytics/events/item';

interface EmailCaptureFormValues {
  email: string;
}

const schema: yup.SchemaOf<EmailCaptureFormValues> = yup.object({
  email: yup.string().required('Required').email('Enter a valid email'),
});

interface Props {
  onSuccessfulSubmit: () => void;
  src?: AnalyticsSource;
}

const EmailCaptureForm: React.FC<Props> = ({ onSuccessfulSubmit, src }) => {
  const router = useRouter();
  return (
    <Formik
      initialValues={{ email: '' }}
      validationSchema={schema}
      validateOnBlur={false}
      validateOnChange={false}
      onSubmit={async (values, formikHelpers) => {
        try {
          await postEmailCapture(values.email, 'FOOTER');
        } catch (e) {
          fireEmailCaptured({
            email: values.email,
            pageName: router.asPath,
            src,
          });
        }

        onSuccessfulSubmit();
        setKnownCookie(null, 'sls_ec', '1');
      }}
    >
      <Form>
        <div className="flex items-end space-x-4">
          <FormikInput
            name="email"
            type="email"
            className="flex-1"
            placeholder="Enter your email"
          />
          <Button className="h-10" type="submit">
            Subscribe
          </Button>
        </div>
        <FormikFieldError name="email" />
      </Form>
    </Formik>
  );
};

export default EmailCaptureForm;
