import { AnalyticsSource } from '../AnalyticsInterfaces';
import fireAnalytics from '~/services/analytics/fireAnalytics';
import {
  RailsBlock,
  RailsPageView,
  RailsTile,
} from '~/typings/services/rails/tile';
import { RailsLander } from '~/typings/services/rails/landers';
import fireGAEvent from '~/services/analytics/fireGAEvent';

export const visitedHomepage = (src?: AnalyticsSource) =>
  fireAnalytics('Visited Homepage', src);

export const visitedSellNow = (src?: AnalyticsSource) =>
  fireAnalytics('Visited Sell Now', src);

export const clickedInviteNow = (src?: AnalyticsSource) =>
  fireAnalytics('Clicked Invite Now', src);

export const visitedInviteFriends = (src?: AnalyticsSource) =>
  fireAnalytics('Visited Invite Friends', src);

export const clickedAppCta = (
  platform?: 'ios' | 'android',
  src?: AnalyticsSource,
) => fireAnalytics('Clicked App CTA', src, { platform });

export const visitedPeranisLander = () =>
  fireAnalytics('Visited Peranis Lander');

export const visitedEcd2020Lander = () =>
  fireAnalytics('Visited ECD2020 Lander');

export const fireClickedTile = (
  tile: RailsTile,
  block?: RailsBlock,
  src?: AnalyticsSource,
) =>
  fireAnalytics('Clicked Tile', src, {
    tile_id: tile.id,
    tile_name: tile.name,
    destination: tile.destination,
    block_title: block?.title,
    block_layout: block?.layout,
  });

export const visitedNavigationalLander = (
  lander: RailsLander,
  pageView?: RailsPageView,
) =>
  fireAnalytics('Visited Navigational Lander', null, {
    lander_id: lander.id,
    lander_name: lander.name,
    page_view_id: pageView?.id,
  });

export const visitedMyFeed = () => fireAnalytics('Visited My Feed');

export const visitedLocker = (page?: string, myLocker?: boolean) => {
  fireGAEvent('set', 'contentGroup1', 'Lockers');

  const params = {
    page,
    role: myLocker ? 'seller' : 'buyer',
    state: page,
  };

  switch (page) {
    case 'followers':
      return fireAnalytics('Visited Followers', null, params);

    case 'following':
      return fireAnalytics('Visited Following', null, params);

    case 'favorites':
      return fireAnalytics('Visited Favorites', null, params);

    case 'feedback':
      return fireAnalytics('Visited Feedback', null, params);

    case 'available':
    case 'sold':
    case 'removed':
    case 'drafts':
    default:
      return fireAnalytics('Visited Locker', null, params);
  }
};

export const fireWebError = (statusCode?: number) => {
  fireAnalytics('Web Error', undefined, {
    statusCode,
    path:
      window.location.pathname + window.location.search + window.location.hash,
  });
};
