import { clsx } from 'clsx';
import React from 'react';
import { CSSProp } from 'styled-components';
import absoluteUrl from '~/utils/absoluteUrl';

export interface AnchorProps extends Omit<Props, 'href'> {}

type Props = React.AnchorHTMLAttributes<HTMLAnchorElement> & {
  as?: string | React.ComponentType;
  css?: CSSProp;
};

const A = React.forwardRef<HTMLAnchorElement, Props>(
  ({ as, children, className, href, ...props }, ref) => {
    const computedHref =
      typeof href === 'string' && href.startsWith('http')
        ? href
        : absoluteUrl(href);

    return (
      <a
        {...props}
        className={clsx(
          'transition-colors duration-200 hover:text-blue-500',
          className,
        )}
        href={computedHref}
        ref={ref}
      >
        {children}
      </a>
    );
  },
);

export default A;
