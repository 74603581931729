import React from 'react';
import { AnchorProps } from '~/components/Links/A';
import NewTabLink from './NewTabLink';

type Props = AnchorProps & {
  page?: string;
};

const BlogLink: React.FC<Props> = ({ children, page, ...props }) => (
  <NewTabLink href={`/blog${page || ''}`} {...props}>
    {children}
  </NewTabLink>
);

export default BlogLink;
