import React from 'react';

interface Props {}

const ItemThumbnailGrid: React.FC<React.PropsWithChildren<Props>> = ({
  children,
  ...props
}) => (
  <div
    {...props}
    className="grid grid-cols-2 gap-x-2 gap-y-4 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-6 xl:gap-4"
    role="grid"
  >
    {children}
  </div>
);

export default ItemThumbnailGrid;
