'use client';

import React, { useState } from 'react';
import EmailCaptureForm from '~/components/NewLockers/EmailCaptureForm';
import { useSession } from '~/contexts/SessionContext';
import useKnownCookie from '~/hooks/useKnownCookie';

interface Props {}

const FooterEmailCapture: React.FC<Props> = () => {
  const { loggedIn } = useSession();
  const [cookieCaptured] = useKnownCookie('sls_ec');
  const [captured, setCaptured] = useState(false);

  if (loggedIn || cookieCaptured === '1') {
    return null;
  }

  return (
    <div className="items-center justify-center md:flex md:space-x-4">
      {captured ? (
        <div className="text-primary-500 w-full text-center text-lg font-semibold">
          Thanks
        </div>
      ) : (
        <>
          <div className="mb-4 text-center text-lg font-semibold md:text-left">
            Get the best deals in your inbox
          </div>
          <EmailCaptureForm
            onSuccessfulSubmit={() => setCaptured(true)}
            src="footer"
          />
        </>
      )}
    </div>
  );
};

export default FooterEmailCapture;
