import { clsx } from 'clsx';
import React from 'react';
import Image from '~/components/Image';
import GooglePlayStoreLink from '~/components/Links/GooglePlayStoreLink';
import IosAppStoreLink from '~/components/Links/IosAppStoreLink';
import IOS_APP_STORE from '~/public/static/ios-app-store.png';
import PlayStoreIcon from '~/public/static/svg/PlayStoreIcon';
import { AnalyticsSource } from '~/services/analytics/AnalyticsInterfaces';

interface Props {
  appText?: React.ReactNode;
  center?: boolean;
  className?: string;
  src?: AnalyticsSource;
}

const GetTheAppFooter: React.FC<Props> = ({
  appText,
  center = true,
  className,
  src = 'footer',
}) => {
  let content;
  if (appText) {
    if (typeof appText === 'string') {
      content = <div className="mb-2 font-semibold">{appText}</div>;
    } else {
      content = appText;
    }
  } else {
    content = <div className="mb-2 font-semibold">Get the App</div>;
  }

  return (
    <div className={clsx({ 'text-center': center }, className)}>
      {content}

      <div
        className={clsx('flex space-x-2', {
          'items-center justify-center': center,
        })}
      >
        <IosAppStoreLink source={src}>
          <Image
            className="inline-block"
            height={35}
            width={110}
            src={IOS_APP_STORE}
            alt="iOS App Store"
          />
        </IosAppStoreLink>

        <GooglePlayStoreLink source={src}>
          <PlayStoreIcon height={35} width={120} />
        </GooglePlayStoreLink>
      </div>
    </div>
  );
};

export default GetTheAppFooter;
