'use client';

import React from 'react';
import { qsStringify } from 'utils';
import { AnchorProps } from '~/components/Links/A';
import { AnalyticsSource } from '~/services/analytics/AnalyticsInterfaces';
import { clickedAppCta } from '~/services/analytics/events/generic';
import NewTabLink from './NewTabLink';

export const appStoreLink =
  'https://itunes.apple.com/app/apple-store/id1215421737?pt=118112125';

type Props = AnchorProps & {
  source: AnalyticsSource;
};

const IosAppStoreLink: React.FC<Props> = ({ children, source, ...props }) => {
  const query = qsStringify({
    ct: source,
    pt: 118112125,
    mt: 8,
  });

  return (
    <NewTabLink
      href={`https://itunes.apple.com/app/apple-store/id1215421737${query}`}
      {...props}
      aria-label="Download our iOS App"
      onClick={() => {
        if (source) {
          clickedAppCta('ios', source);
        }
      }}
    >
      {children}
    </NewTabLink>
  );
};

export default IosAppStoreLink;
