import { Popover, PopoverButton, PopoverPanel } from '@headlessui/react';
import { LogoTagline } from 'common-nextjs';
import Link from 'next/link';
import React from 'react';
import FooterEmailCapture from '~/components/Chrome/FooterEmailCapture';
import GetTheAppFooter from '~/components/Chrome/GetTheAppFooter';
import Image from '~/components/Image';
import BlogLink from '~/components/Links/BlogLink';
import CareersLink from '~/components/Links/CareersLink';
import GooglePlayStoreLink from '~/components/Links/GooglePlayStoreLink';
import HelpLink from '~/components/Links/HelpLink';
import IosAppStoreLink from '~/components/Links/IosAppStoreLink';
import SellNowLink from '~/components/Links/SellNowLink';
import ShopLink from '~/components/Links/ShopLink';
import SocialLink from '~/components/Links/SocialLink';
import TermsLink from '~/components/Links/TermsLink';
import ValueGuideLink from '~/components/Links/ValueGuideLink';
import IOS_APP_STORE from '~/public/static/ios-app-store.png';
import PlayStoreIcon from '~/public/static/svg/PlayStoreIcon';
import {
  FacebookIcon,
  InstagramIcon,
  TikTokIcon,
  TwitterIcon,
  YoutubeIcon,
} from '~/public/static/svg/SocialIcons';
import TrustpilotFooter from './TrustpilotFooter';

const ListSection: React.FC<React.PropsWithChildren<{ title: string }>> = ({
  children,
  title,
}) => (
  <ul className="space-y-3">
    <li className="text-slate-green-500 text-sm font-semibold uppercase">
      {title}
    </li>
    {children}
  </ul>
);

const Footer: React.FC<{
  excludeAppCta?: boolean;
  mobile?: boolean;
  emailCapture?: boolean;
}> = ({ emailCapture = true, excludeAppCta, mobile }) => (
  <footer className="bg-turf-green-500 text-pastel-green-500 mt-12 py-8 md:py-12 print:hidden">
    <div className="container px-4">
      <div className="grid auto-cols-auto grid-flow-row gap-6 md:grid-flow-col">
        <div className="max-w-sm text-center md:text-left">
          <LogoTagline className="mx-auto mb-2 md:mx-0 md:mb-6" />

          <p className="mb-4 leading-loose md:mb-6">
            We&apos;re proud to be making sports more affordable, while keeping
            gear out of landfills.{' '}
            <CareersLink className="underline">Learn more</CareersLink>
          </p>

          <div className="text-slate-green-500 flex justify-center gap-6 md:justify-start">
            <Popover className="relative">
              <PopoverButton>
                <InstagramIcon />
              </PopoverButton>

              <PopoverPanel className="absolute -left-4 bottom-full mb-2 w-48 divide-y rounded border bg-white shadow-xl">
                <SocialLink
                  link="instagramHockey"
                  className="flex w-full gap-4 p-4"
                >
                  <InstagramIcon />
                  <span>Hockey</span>
                </SocialLink>

                <SocialLink
                  link="instagramLacrosse"
                  className="flex w-full gap-4 p-4"
                >
                  <InstagramIcon />
                  <span>Lacrosse</span>
                </SocialLink>
              </PopoverPanel>
            </Popover>

            <ul className="hidden">
              <li>
                <SocialLink link="instagramHockey">Instagram Hockey</SocialLink>
              </li>
              <li>
                <SocialLink link="instagramLacrosse">
                  Instagram Lacrosse
                </SocialLink>
              </li>
            </ul>

            <SocialLink link="facebook" title="Facebook">
              <FacebookIcon />
            </SocialLink>

            <Popover className="relative">
              <PopoverButton>
                <YoutubeIcon />
              </PopoverButton>

              <PopoverPanel className="absolute -left-4 bottom-full mb-2 w-48 divide-y rounded border bg-white shadow-xl">
                <SocialLink
                  link="youtubeBaseball"
                  className="flex w-full gap-4 p-4"
                >
                  <YoutubeIcon />
                  <span>Baseball</span>
                </SocialLink>

                <SocialLink
                  link="youtubeFootball"
                  className="flex w-full gap-4 p-4"
                >
                  <YoutubeIcon />
                  <span>Football</span>
                </SocialLink>

                <SocialLink
                  link="youtubeHockey"
                  className="flex w-full gap-4 p-4"
                >
                  <YoutubeIcon />
                  <span>Hockey</span>
                </SocialLink>

                <SocialLink
                  link="youtubeLacrosse"
                  className="flex w-full gap-4 p-4"
                >
                  <YoutubeIcon />
                  <span>Lacrosse</span>
                </SocialLink>
              </PopoverPanel>
            </Popover>

            <ul className="hidden">
              <li>
                <SocialLink link="youtubeBaseball">YouTube Baseball</SocialLink>
              </li>
              <li>
                <SocialLink link="youtubeFootball">YouTube Football</SocialLink>
              </li>
              <li>
                <SocialLink link="youtubeHockey">YouTube Hockey</SocialLink>
              </li>
              <li>
                <SocialLink link="youtubeLacrosse">YouTube Lacrosse</SocialLink>
              </li>
            </ul>

            <SocialLink link="twitter" title="Twitter">
              <TwitterIcon />
            </SocialLink>

            <SocialLink link="tiktok" title="TikTok">
              <TikTokIcon className="h-6 w-6" />
            </SocialLink>

            <BlogLink title="SidelineSwap's Blog">Blog</BlogLink>
          </div>
        </div>

        <ListSection title="Customer Support">
          <li>
            <HelpLink link="shipping">Shipping FAQ</HelpLink>
          </li>
          <li>
            <HelpLink link="refundPolicy">Refunds</HelpLink>
          </li>
          <li>
            <HelpLink link="buyerProtection">Buyer Protection</HelpLink>
          </li>
          <li>
            <HelpLink link="default">Help Center</HelpLink>
          </li>
        </ListSection>

        <ListSection title="Sell on SidelineSwap">
          <li>
            <ValueGuideLink>Value Guide</ValueGuideLink>
          </li>

          <li>
            <SellNowLink>How to Sell</SellNowLink>
          </li>

          <li>
            <ShopLink type="nav" path="how-to-ship/l163448">
              How to Ship
            </ShopLink>
          </li>

          <li>
            <HelpLink link="ims">Seller Integrations</HelpLink>
          </li>
        </ListSection>

        <ListSection title="Company">
          <li>
            <CareersLink>About Us</CareersLink>
          </li>
          <li>
            <HelpLink link="careers">Careers</HelpLink>
          </li>
          <li>
            <HelpLink link="affiliates">Affiliates</HelpLink>
          </li>
          <li>
            <TermsLink type="terms">Terms</TermsLink>
          </li>
          <li>
            <TermsLink type="privacy">Privacy</TermsLink>
          </li>
          <li>
            <TermsLink type="accessibility">Accessibility</TermsLink>
          </li>
          <li>
            <HelpLink link="press">Press</HelpLink>
          </li>
          <li>
            <Link href="/sitemap/index">Sitemap</Link>
          </li>
        </ListSection>

        {mobile ? (
          <GetTheAppFooter className="text-slate-green-500 font-semibold" />
        ) : (
          <ListSection title="Get the App">
            <li>
              <IosAppStoreLink source="footer" className="mr-2">
                <Image
                  className="inline-block md:mr-8"
                  height={35}
                  width={118}
                  src={IOS_APP_STORE}
                  alt="iOS App Store"
                />
              </IosAppStoreLink>
            </li>

            <li>
              <GooglePlayStoreLink source="footer">
                <PlayStoreIcon height={35} width={120} />
              </GooglePlayStoreLink>
            </li>
          </ListSection>
        )}
      </div>

      <TrustpilotFooter />

      <hr className="my-8" />

      {emailCapture && <FooterEmailCapture />}

      <div className="text-slate-green-500 pt-12 text-center">
        SidelineSwap, Inc.
      </div>
    </div>
  </footer>
);

export default Footer;
