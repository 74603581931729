'use client';

import React from 'react';
import { AnchorProps } from '~/components/Links/A';
import { AnalyticsSource } from '~/services/analytics/AnalyticsInterfaces';
import { clickedAppCta } from '~/services/analytics/events/generic';
import NewTabLink from './NewTabLink';

export const playStoreLink =
  'https://play.google.com/store/apps/details?id=com.sidelineswap.android';

type Props = AnchorProps & {
  source?: AnalyticsSource;
};

const GooglePlayStoreLink: React.FC<Props> = ({
  children,
  source,
  ...props
}) => {
  return (
    <NewTabLink
      href={playStoreLink}
      {...props}
      aria-label="Download our Android App"
      onClick={() => {
        if (source) {
          clickedAppCta('android', source);
        }
      }}
    >
      {children}
    </NewTabLink>
  );
};

export default GooglePlayStoreLink;
