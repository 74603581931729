import React from 'react';
import NewTabLink from '~/components/Links/NewTabLink';

export const socialLinks = {
  facebook: 'https://www.facebook.com/SidelineSwap',
  instagram: 'https://www.instagram.com/sidelineswap/',
  instagramHockey: 'https://www.instagram.com/sidelineswap.hockey/',
  instagramLacrosse: 'https://www.instagram.com/sidelineswap/',
  youtube: 'https://www.youtube.com/channel/UCwTgtTvQ8VdQ16-MnwU_Khg',
  youtubeBaseball: 'https://www.youtube.com/@SidelineSwapBaseball',
  youtubeFootball: 'https://www.youtube.com/@SidelineSwapFootball',
  youtubeHockey: 'https://www.youtube.com/channel/UCLpHsLshqKjie46hcv7j25w',
  youtubeLacrosse: 'https://www.youtube.com/c/SidelineSwapLacrosse',
  tiktok: 'https://www.tiktok.com/@sidelineswap.com',
  twitter: 'https://www.twitter.com/sidelineswap',
};

type Props = React.AnchorHTMLAttributes<HTMLAnchorElement> & {
  link: keyof typeof socialLinks;
};

const SocialLink: React.FC<Props> = ({ children, link, ...props }) => (
  <NewTabLink {...props} href={socialLinks[link]}>
    {children}
  </NewTabLink>
);

export default SocialLink;
