import React from 'react';
import NewTabLink from '~/components/Links/NewTabLink';

type Props = React.AnchorHTMLAttributes<HTMLAnchorElement>;

const CareersLink: React.FC<Props> = ({ children, ...props }) => (
  <NewTabLink {...props} href="https://sidelineswap.recruitee.com/">
    {children}
  </NewTabLink>
);

export default CareersLink;
