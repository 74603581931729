import { fetcher, getBaseUrl, makeFetcher } from 'fetcher-session';
import {
  FlattenedFilterState,
  RailsFacetItemMeta,
  RailsFacetQueryOptions,
  RailsFlattenedFacetQuery,
} from '~/typings/services/rails/facets';
import { RailsItem, RailsThumbnailItem } from '~/typings/services/rails/item';
import { RailsFacetResponse } from '~/typings/services/rails/responses';

export const fetchUserFavorites = makeFetcher<RailsFacetResponse>(
  (page: number | string = 1, pageSize?: number | string) => [
    ({ uuid }) => `/v1/users/${uuid}/favorites`,
    {
      baseUrl: getBaseUrl('edge'),
      dataOnly: false,
      params: {
        page,
        page_size: pageSize,
        sort: 'newest',
        src: 'locker',
      },
    },
  ],
);

export const fetchMultipleItemsByIds = (itemIds: number[]) =>
  fetcher<RailsThumbnailItem[]>('/v2/facet_items', {
    baseUrl: getBaseUrl('edge'),
    params: {
      include_ids: itemIds.join(','),
      include_meta: 0,
      state: ['available'],
    },
  });

export const fetchFacetMetaNoItems = (query: FlattenedFilterState) =>
  fetcher<RailsFacetResponse>('/v2/facet_items', {
    dataOnly: false,
    baseUrl: getBaseUrl('edge'),
    params: {
      include_data: 0,
      ...query,
    },
  });

export const fetchFacetUserMeta = (
  itemIds: number[],
  additionalParams?: RailsFlattenedFacetQuery,
  context?: 'locker' | 'results',
) =>
  fetcher<RailsFacetItemMeta[]>('/v2/facet_items/meta', {
    params: {
      ...additionalParams,
      context,
      include_ids: itemIds.join(','),
      page_size: itemIds.length,
    },
  });

export const fetchFacetItemsNoMeta = makeFetcher<RailsThumbnailItem[]>(
  (query: RailsFacetQueryOptions) => [
    '/v2/facet_items',
    {
      baseUrl: getBaseUrl('edge'),
      params: query,
    },
  ],
);

export const fetchLockerItemsMeta = makeFetcher<
  RailsFacetItemMeta[],
  [itemIds: string, username: string, pageSize: number]
>((itemIds: string, username: string, pageSize: number) => [
  '/v2/facet_items/meta',
  {
    params: {
      include_ids: itemIds,
      page_size: pageSize,
      seller: [username],
      src: 'locker',
    } as RailsFacetQueryOptions,
  },
]);

export const fetchRelatedModelItems = makeFetcher<RailsThumbnailItem[]>(
  (item: RailsItem, pageSize = 6) => [
    '/v2/facet_items',
    {
      baseUrl: getBaseUrl('edge'),
      params: {
        page_size: pageSize,
        include_meta: 0,
        model: item.model && [item.model.id],
        category: [item.categories[0].id],
        similar_to: item.id,
      } as RailsFacetQueryOptions,
    },
  ],
);

export const fetchRelatedItems = makeFetcher<
  RailsFacetResponse,
  [RailsItem] | [RailsItem, number | undefined]
>((item: RailsItem, count = 12) => [
  '/v2/facet_items',
  {
    baseUrl: getBaseUrl('edge'),
    dataOnly: false,
    params: {
      page_size: count,
      category: [item.categories[0].id],
      similar_to: item.id,
      include_meta: 0,
    } as RailsFacetQueryOptions,
  },
]);

export interface RailsItemRemoveReasons {
  slug: string;
  label: string;
}

export const fetchItemRemoveReasons = () =>
  fetcher<RailsItemRemoveReasons[]>('/v1/items/remove_reasons');
