import { CustomLinkProps } from 'common-nextjs';
import Link from 'next/link';

function HomePageLink({ children, ...props }: CustomLinkProps) {
  return (
    <Link {...props} aria-label="Go to the Homepage" href="/">
      {children}
    </Link>
  );
}

export default HomePageLink;
